<template>
  <b-card
    no-body
    class="border"
    :style="{
      'border-color':
        (alertCompetitorSelect.color || alertBrandSelect.brandColor) +
        ' !important'
    }"
  >
    <b-card-header
      :style="{
        'background-color':
          alertCompetitorSelect.color || alertBrandSelect.brandColor
      }"
    >
      <h6 class="mb-0 text-white">Incident Link</h6>
    </b-card-header>
    <b-card-body>
      <b-form autocomplete="off">
        <b-form-group
          label="Select Facebook Post Type"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="selectedPostType"
            :options="postTypes"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <label for="link">Link <span class="text-danger">*</span></label>
          <div class="input-icon input-icon-right">
            <b-input-group>
              <b-form-input
                size="sm"
                v-model="form.link"
                ref="link"
                id="link"
                @change="onChangeLink()"
              >
              </b-form-input>
              <b-input-group-append
                v-if="resolveState == 'retry'"
                @click="resolve(false)"
              >
                <b-button size="sm" variant="outline-success"
                  ><i class="flaticon-refresh"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>

            <span v-if="resolveState === 'resolving'"
              ><i class="spinner spinner-info mr-5 "></i
            ></span>
            <span v-if="resolveState === 'success'"
              ><i class="flaticon2-check-mark text-success"></i
            ></span>
            <span v-if="resolveState === 'exist'" @click="resolve(true)"
              ><i class="text-danger flaticon-exclamation-1"></i
            ></span>
          </div>
        </b-form-group>
        <b-form-group v-if="similarLinks.length">
          <b-row>
            <b-col>
              <SimilarLink
                v-if="resolveState === 'ready'"
                :suggested-links="similarLinks"
                @resolve="resolve(false)"
              ></SimilarLink>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <div class="d-flex flex-row">
            <b-form-checkbox
              id="personalPost"
              v-model="form.personalPost"
              :value="true"
              :unchecked-value="false"
            >
              Personal Post
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="facebookGroup"
              v-model="form.isFacebookGroup"
              :value="true"
              :unchecked-value="false"
            >
              Facebook Group
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="isEngagement"
              v-model="isEngagement"
              :value="true"
              :unchecked-value="false"
            >
              Engagement
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="isRemarkDate"
              v-model="isRemarkDate"
              :value="true"
              :unchecked-value="false"
            >
              Date
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col sm="4">
              <label for="source"
                >Source <span class="text-danger">*</span></label
              >

              <b-select
                :options="sources"
                size="sm"
                v-model="form.source"
                id="source"
              ></b-select>
            </b-col>
            <b-col sm="4">
              <label for="username"
                >Username <span class="text-danger">*</span></label
              >
              <b-form-input
                size="sm"
                type="text"
                v-model="form.username"
                id="username"
              ></b-form-input>
            </b-col>
            <b-col sm="4">
              <label for="pageName">Page Name</label>
              <b-form-input
                size="sm"
                type="text"
                v-model="form.pageName"
                id="pageName"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col sm="8">
              <label for="date"
                >Post Date <span class="text-danger">*</span></label
              >
              <div>
                <el-date-picker
                  class="w-100"
                  v-model="form.date"
                  type="datetime"
                  size="sm"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm"
                  value-format="timestamp"
                  :default-value="new Date()"
                  :clearable="false"
                >
                </el-date-picker>
              </div>
            </b-col>
            <b-col sm="4">
              <label for="fanCount">Fan Count</label>
              <b-form-input
                size="sm"
                type="text"
                v-model="form.fanCount"
                id="fanCount"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col sm="3">
              <label><i class="mr-1 flaticon-like"></i>Like</label>
              <b-form-input
                size="sm"
                type="number"
                min="0"
                v-model="form.count.like"
              ></b-form-input>
            </b-col>
            <b-col sm="3">
              <label><i class="mr-1 flaticon-comment"></i>Comment</label>
              <b-form-input
                size="sm"
                type="number"
                min="0"
                v-model="form.count.comment"
              ></b-form-input>
            </b-col>
            <b-col sm="3">
              <label><i class="mr-1 flaticon-share"></i>Share/Retweet</label>
              <b-form-input
                size="sm"
                type="number"
                min="0"
                v-model="form.count.retweet"
              ></b-form-input>
            </b-col>
            <b-col sm="3">
              <label><i class="mr-1 flaticon-eye"></i>View</label>
              <b-form-input
                size="sm"
                type="number"
                min="0"
                v-model="form.count.view"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <label for="text">Text <span class="text-danger">*</span></label>

          <b-form-textarea
            type="text"
            size="lg"
            rows="3"
            v-model="form.text"
            id="text"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group>
          <label for="remark">Remark <span class="text-danger">*</span></label>

          <b-form-textarea
            type="text"
            rows="7"
            size="lg"
            v-model="form.remark"
            id="remark"
          ></b-form-textarea>
        </b-form-group>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <label for="intention">Intention</label>

              <b-select
                :options="intentionOpts"
                v-model="form.intention"
                id="intention"
              ></b-select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label for="statuses">Statuses</label>

              <b-select
                :options="statusOpts"
                v-model="form.status"
                id="statuses"
              ></b-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="product">Product</label>
              <b-select
                size="sm"
                :options="productOptions"
                v-model="form.product"
                id="product"
              ></b-select>
              <!-- <b-form-input size="sm" v-model="form.product"></b-form-input> -->
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="model">Model</label>
              <b-form-input size="sm" v-model="form.model"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="summary">Summary</label>
              <b-select
                size="sm"
                :options="summaryOptions"
                v-model="form.summaryTmp"
                id="product"
                @change="
                  form.summary = form.summaryTmp.includes('other')
                    ? ''
                    : form.summaryTmp
                "
              ></b-select>

              <b-form-input
                class="mt-2"
                placeholder="Enter summary"
                v-if="form.summaryTmp.includes('other')"
                size="sm"
                v-model="form.summary"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Alert Label">
          <b-form-radio-group
            v-model="form.alertLabel"
            :options="[
              { text: 'Default', value: 'default' },
              { text: 'Level 0: Buying Signal', value: 'level0' },
              { text: 'Level 1: Low Crisis Level', value: 'level1' },
              {
                text: 'Level 2: Moderate Crisis Level',
                value: 'level2'
              },
              { text: 'Level 3: Severe Crisis Level', value: 'level3' },
              { text: 'Level 4: Catastrophic Crisis Level', value: 'level4' }
            ]"
          ></b-form-radio-group>
        </b-form-group>
      </b-form>
    </b-card-body>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <b-button variant="success" class="mx-1" @click="submitRecord">
          Record
        </b-button>
        <b-button variant="warning" class="mx-1" @click="submitAlert">
          Alert
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { LineIncidentLogsService, UtilsService } from "@/core/services"
import SimilarLink from "@/view/content/custom/SimilarLink"
import Cookies from "js-cookie"
import moment from "moment"
import { mapGetters } from "vuex"
moment.locale("th")

const isValidPostDate = date => {
  return Number(moment(date).format("YYYY")) > 1970
}

export default {
  name: "LineIncidentResolve",
  components: {
    SimilarLink
  },
  props: {
    selectedBrand: {
      type: Object,
      required: true
    },
    competitorId: String
  },
  data() {
    return {
      postTypes: [
        { text: "Personal", value: "personal" },
        { text: "Non-Personal", value: null }
      ],
      selectedPostType: null,
      resolveState: "ready", // ready,resolving,success,exist
      sources: [
        "facebook",
        "twitter",
        "pantip",
        "youtube",
        "website",
        "instagram",
        "tiktok"
      ],
      remarks: [],
      form: {
        postId: null,
        pageName: null,
        link: null,
        source: null,
        username: null,
        text: null,
        date: null,
        count: {
          like: null,
          comment: null,
          retweet: null,
          view: null
        },
        intention: null,
        isSend: true,
        remark: "",
        personalPost: false,
        isFacebookGroup: false,
        fanCount: null,
        alertLabel: "default",

        product: "",
        model: "",
        summary: "",
        summaryTmp: ""
      },
      productOptions: [
        { text: "-", value: "" },
        { text: "Tractor", value: "Tractor" },
        { text: "Combine Harvester", value: "Combine Harvester" },
        { text: "Rice Transplanter", value: "Rice Transplanter" },
        { text: "Drone", value: "Drone" },
        { text: "Mini Excavator", value: "Mini Excavator" },
        { text: "Diesel", value: "Diesel" },
        { text: "Power Tiller", value: "Power Tiller" },
        { text: "Implement", value: "Implement" },
        { text: "Kin", value: "Kin" },
        { text: "New business", value: "New business" },
        { text: "Spare part", value: "Spare part" },
        { text: "Non-Farmer", value: "Non-Farmer" }
      ],
      summaryOptions: [
        { text: "-", value: "" },
        {
          label:
            "1. มีการบอกปัญหาที่เกิดขึ้นกับแทรคเตอร์ พร้อมทั้งขอคำแนะนำและวิธีแก้ไข",
          options: [
            {
              value: "1.1 น้ำมันรั่ว",
              text: "1.1 น้ำมันรั่ว"
            },
            {
              value: "1.2 อะไหล่หลุด",
              text: "1.2 อะไหล่หลุด"
            },
            {
              value: "1.3 สอบถามวิธีซ่อมอะไหล่",
              text: "1.3 สอบถามวิธีซ่อมอะไหล่"
            },
            {
              value: "1.4 ผาน หลวม ยกไม่ขึ้น/ยกไม่สูง/เอียง",
              text: "1.4 ผาน หลวม ยกไม่ขึ้น/ยกไม่สูง/เอียง"
            },
            {
              value: "1.5 เฟืองท้ายดัง",
              text: "1.5 เฟืองท้ายดัง"
            },
            {
              value: "1.6 ล้อส่าย",
              text: "1.6 ล้อส่าย"
            },
            {
              value: "1.7 สตาร์ทไม่ติด",
              text: "1.7 สตาร์ทไม่ติด"
            },
            {
              value: "1.8 สายพานสั่น / เสียงดัง",
              text: "1.8 สายพานสั่น / เสียงดัง"
            },
            {
              value: "1.9 รถไถไม่ตรง/เป๋",
              text: "1.9 รถไถไม่ตรง/เป๋"
            },
            {
              value: "1.10 ไฟโชว์ / ไมค์ไม่ขึ้น",
              text: "1.10 ไฟโชว์ / ไมค์ไม่ขึ้น"
            },
            {
              value: "1.11 พวงมาลัยหนักเวลาเลี้ยว",
              text: "1.11 พวงมาลัยหนักเวลาเลี้ยว"
            },
            {
              value: "1.12 ซิลรั่ว",
              text: "1.12 ซิลรั่ว"
            },
            {
              value: "1.13 รถความร้อนขึ้น",
              text: "1.13 รถความร้อนขึ้น"
            },
{
              value: "1.14 มีเสียงดังผิดปกติ",
              text: "1.14 มีเสียงดังผิดปกติ"
            },
            {
              value: "1.15 ปัญหาเกียร์ เช่น เกียร์ไม่ทำงาน เข้าเกียร์ยาก",
              text: "1.15 ปัญหาเกียร์ เช่น เกียร์ไม่ทำงาน เข้าเกียร์ยาก"
            },
            {
              value: "1.16 ปัญหาครัช เช่น ครัชแข็ง ครัชลื่น",
              text: "1.16 ปัญหาครัช เช่น ครัชแข็ง ครัชลื่น"
            },{
              value: "1.17 รถเลี้ยวไม่ได้",
              text: "1.17 รถเลี้ยวไม่ได้"
            },
            {
              value: "1.18 other",
              text: "1.18 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "2. มีการสอบถาม ขอคำแนะนำเรื่องการใช้งานทั่วไป",
          options: [
            {
              value: "2.1 สอบถามวิธีใส่/ถอดอะไหล่",
              text: "2.1 สอบถามวิธีใส่/ถอดอะไหล่"
            },
            {
              value: "2.2 สอบถามวิธีแปลงอะไหล่",
              text: "2.2 สอบถามวิธีแปลงอะไหล่"
            },
            {
              value: "2.3 สอบถามการเลือกรถไถ/อะไหล่ที่เหมาะสมกับการใช้งาน",
              text: "2.3 สอบถามการเลือกรถไถ/อะไหล่ที่เหมาะสมกับการใช้งาน"
            },
            {
              value: "2.4 สอบถามหน้าที่รถไถ/อะไหล่",
              text: "2.4 สอบถามหน้าที่รถไถ/อะไหล่"
            },
            {
              value:
                "2.5 สอบถามรีวิว ขอคำแนะนะสมาชิกในกลุ่ม ว่าใช้ดีไหม น่าใช้ไหม",
              text:
                "2.5 สอบถามรีวิว ขอคำแนะนะสมาชิกในกลุ่ม ว่าใช้ดีไหม น่าใช้ไหม"
            },
            {
              value: "2.6 other",
              text: "2.6 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "3. มีการสอบถามเกี่ยวกับราคา",
          options: [
            {
              value: "3.1 สอบถามราคาซ่อมรถไถ/อะไหล่",
              text: "3.1 สอบถามราคาซ่อมรถไถ/อะไหล่"
            },
            {
              value: "3.2 สอบถามราคารถไถ/อะไหล่ที่เกี่ยวกับศูนย์",
              text: "3.2 สอบถามราคารถไถ/อะไหล่ที่เกี่ยวกับศูนย์"
            },
            {
              value: "3.3 สอบถามราคาซื้อ/ขายรถไถ อะไหล่",
              text: "3.3 สอบถามราคาซื้อ/ขายรถไถ อะไหล่"
            },
            {
              value: "3.4 other",
              text: "3.4 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "4. สอบถามรายละเอียดการซื้อรถ อะไหล่",
          options: [
            {
              value: "4.1 สอบถามรายละเอียดการผ่อน ดาวน์รถผ่านศูนย์",
              text: "4.1 สอบถามรายละเอียดการผ่อน ดาวน์รถผ่านศูนย์"
            },
            {
              value:
                "4.2 สอบถามเชิงให้ความสนใจซื้อ เช่น สอบถามขอรายละเอียดเพิ่มเติม/คอมเม้นว่าสนใจ ขอรายละเอียด",
              text:
                "4.2 สอบถามเชิงให้ความสนใจซื้อ เช่น สอบถามขอรายละเอียดเพิ่มเติม/คอมเม้นว่าสนใจ ขอรายละเอียด"
            },
            {
              value: "4.3 other",
              text: "4.3 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "5. มีการโพสต์ตำหนิรถไถ/อะไหล่ว่าใช้งานได้ไม่ดี ไม่สมราคา",
          options: [
            {
              value: "5.1 โพสต์ติว่าสินค้าไม่ดี พังง่าย ไม่สมราคา",
              text: "5.1 โพสต์ติว่าสินค้าไม่ดี พังง่าย ไม่สมราคา"
            },
            {
              value: "5.2 โพสต์ติว่าราคาแพง",
              text: "5.2 โพสต์ติว่าราคาแพง"
            },
            {
              value: "5.3 other",
              text: "5.3 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "6. มีการโพสต์ตำหนิเรื่องการให้บริการของเจ้าหน้าที่/ศูนย์",
          options: [
            {
              value: "6.1 ตำหนิพนักงานคูโบต้า",
              text: "6.1 ตำหนิพนักงานคูโบต้า"
            },
            {
              value: "6.2 ตำหนิเรื่องรอซ่อมนาน/รออะไหล่นาน",
              text: "6.2 ตำหนิเรื่องรอซ่อมนาน/รออะไหล่นาน"
            },
            {
              value: "6.3 other",
              text: "6.3 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "7. ประเด็นอุบัติเหตุต่างๆ",
          options: [
            {
              value: "7.1 ไฟไหม้",
              text: "7.1 ไฟไหม้"
            },
            {
              value: "7.2 รถชน/รถคว่ำ ทับคน",
              text: "7.2 รถชน/รถคว่ำ ทับคน"
            },
            {
              value: "7.3 other",
              text: "7.3 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },
        {
          label: "8. มิจฉาชีพหลอกซื้อขายสินค้า",
          options: [
            {
              value: "8.1 มิจฉาชีพแอบอ้างสยามคูโบต้า",
              text: "8.1 มิจฉาชีพแอบอ้างสยามคูโบต้า"
            },
            {
              value:
                "8.2 มิจฉาชีพเป็นบุคคลทั่วไปหลอกขายสินค้า ไม่ได้แอบอ้างสยามคูโบต้า",
              text:
                "8.2 มิจฉาชีพเป็นบุคคลทั่วไปหลอกขายสินค้า ไม่ได้แอบอ้างสยามคูโบต้า"
            },
            {
              value: "8.3 other",
              text: "8.3 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        },

        {
          label: "9. ประเด็นข่าวพาดพิงสยามคูโบต้า",
          options: [
            {
              value: "9.1 other",
              text: "9.1 อื่นๆ (ใส่ประเด็นที่เจอ)"
            }
          ]
        }
      ],

      isEngagement: false,
      isRemarkDate: false,
      statusOpts: [
        "-",
        "จนท.รับเรื่องแล้ว",
        "ยังไม่มีจนท.รับเรื่อง ยังไม่เกิน 30 นาที",
        "ยังไม่มีจนท.รับเรื่องเกิน 30 นาที",
        "ไม่ tag #kbank",
        "tag #kbank / จนท.รับเรื่องแล้ว",
        "tag @KBank_Live / จนท.รับเรื่องแล้ว",
        "tag #kbank / ยังไม่มีจนท.รับเรื่อง ยังไม่เกิน 30 นาที",
        "tag #kbank / ยังไม่มีจนท.รับเรื่องเกิน 30 นาที",
        "tag @KBank_Live / ยังไม่มีจนท.รับเรื่อง ยังไม่เกิน 30 นาที",
        "tag @KBank_Live / ยังไม่มีจนท.รับเรื่องเกิน 30 นาที"
      ],
      similarLinks: [],
      isProcessing: false,
      personalText: "Personal Post\n"
    }
  },
  validations: {
    form: {
      link: { required },
      source: { required },
      username: { required },
      text: { required },
      date: { required, isValidPostDate },
      count: { required }
    }
  },
  watch: {
    selectedBrand: {
      handler: function() {
        this.resetForm()
      },
      deep: true
    },
    isRemarkDate: function(newIsRemarkDate) {
      if (newIsRemarkDate) {
        this.remarks[0] = "*" + this.getRemarkDate() + "*" + "\n"
      } else {
        this.remarks[0] = ""
      }
      this.genRemarkText()
    },
    "form.personalPost": function(newPersonalPost) {
      if (newPersonalPost) {
        this.isEngagement = true
        this.isRemarkDate = true
      }

      this.genRemarkText()
    },
    "form.isFacebookGroup": function(newIsFacebookGroup) {
      if (newIsFacebookGroup) {
        this.isEngagement = true
        this.isRemarkDate = true
      }

      this.genRemarkText()
    },

    isEngagement: function(newIsEngagement) {
      if (newIsEngagement) {
        this.remarks[2] = this.getRemarkText()
      } else {
        this.remarks[2] = ""
      }
      this.genRemarkText()
    },

    alertBrandSelect: function(brand) {
      this.statusOpts = ["-"].concat(brand.statuses)
    }
  },
  computed: {
    ...mapGetters(["shareData"]),
    alertBrandSelect() {
      return this.shareData("alertBrandSelect")
    },
    alertCompetitorSelect() {
      return this.shareData("alertCompetitorSelect")
    },
    intentionOpts() {
      return this.alertBrandSelect ? this.alertBrandSelect.intention : []
    },

    summaryOptionsWithLabel() {
      return this.summaryOptions
        .filter(v => v.label)
        .map(parent =>
          parent.options.map(child => ({ parent: parent.label, ...child }))
        )
        .reduce((prev, curr) => {
          return prev.concat(curr)
        }, [])
    }
  },
  mounted() {
    if (this.$route.query.link && this.$route.query.link.startsWith("http")) {
      this.form.link = this.$route.query.link
      this.onChangeLink()
    }

    // UtilsService.getIntentions().then(response => {
    //   this.intentionOpts = response.intentions;
    //   this.form.intention = this.intentionOpts[0];
    // });
    this.form.intention = this.intentionOpts[0]

    this.form.status = "-"

    window.addEventListener("focus", this.resolveLinkFromCookie)
  },
  destroyed() {
    window.removeEventListener("focus", this.resolveLinkFromCookie)
  },
  methods: {
    toBuddhistYear(moment, format) {
      let christianYear = moment.format("YYYY")
      let buddhishYear = (parseInt(christianYear) + 543).toString()
      return (
        "วันที่ " +
        moment
          .format(
            format
              .replace("YYYY", buddhishYear)
              .replace("YY", buddhishYear.substring(2, 4))
          )
          .replace(christianYear, buddhishYear)
      )
    },

    getRemarkDate() {
      return this.toBuddhistYear(moment(this.form.date), "LL")
    },
    genRemarkText() {
      setTimeout(() => {
        this.form.remark = this.remarks.filter(v => v.length).join("\n")
      }, 100)
    },
    resetForm() {
      // this.resolveState = "ready";
      this.similarLinks = []
      this.remarks = []
      this.isEngagement = false
      this.isRemarkDate = false
      this.form = Object.assign(
        {},
        {
          ...this.form,
          link: null,
          source: null,
          username: null,
          text: null,
          date: null,
          count: {
            like: null,
            comment: null,
            retweet: null,
            view: null
          },
          status: "-",
          remark: "",
          isSend: true,
          personalPost: false,
          fanCount: null,
          alertLabel: "default",
          isFacebookGroup: false,
          pageName: null,
          postId: null,

          product: "",
          model: "",
          summary: "",
          summaryTmp: ""
        }
      )

      this.form.intention = this.intentionOpts[0]
    },
    convertSummary(data) {
      let parent = this.summaryOptionsWithLabel.find(
        v => v.value === data.summaryTmp
      )

      let summary = data.summary
      if(parent) {
        summary = `${parent.parent}|${data.summary}`
      }


      if (data.summaryTmp.includes("other")) {
        let tmpSummary = data.summaryTmp.replace("other", `${data.summary}`)
        summary = `${parent.parent}|${tmpSummary}`
      }

      return summary
    },
    async submitAlert(e) {
      this.form.isSend = true
      e.preventDefault()
      this.isProcessing = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.isProcessing = false
        return
      }
      let data = Object.assign({}, this.form)
      data.cusId = this.selectedBrand.cusId
      data.competitorId = this.competitorId

      data.summary = this.convertSummary(data)

      let response = await LineIncidentLogsService.create(data)
      if (response) {
        this.$emit("success")
        this.$root.$bvToast.toast("Create incident alert success", {
          title: `Success`,
          variant: "success",
          solid: true
        })
        this.resetForm()
        this.resolveState = "ready"
        this.$emit("submit")
      }

      this.isProcessing = false
      this.$refs.link.focus()
    },
    async submitRecord(e) {
      this.form.isSend = false
      e.preventDefault()
      this.isProcessing = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.isProcessing = false
        return
      }
      let data = Object.assign({}, this.form)
      data.cusId = this.selectedBrand.cusId
      data.competitorId = this.competitorId

      data.summary = this.convertSummary(data)

      let response = await LineIncidentLogsService.create(data)
      if (response) {
        this.$emit("success")
        this.$root.$bvToast.toast("Create incident alert success", {
          title: `Success`,
          variant: "success",
          solid: true
        })
        this.resolveState = "ready"
        this.resetForm()

        this.$emit("submit")
        // this.$router.replace("/line-incidents")
      }

      this.isProcessing = false
      this.$refs.link.focus()
    },
    async onChangeLink() {
      let link = decodeURI(this.form.link)

      this.resolveState = "ready"
      this.resetForm()
      this.form.link = link

      if (!link) return

      let response = await UtilsService.getBrandLinkSuggestion({
        cusId: this.selectedBrand.cusId,
        link: this.form.link,
        postType: this.selectedPostType
      })

      if (!response.error) {
        this.similarLinks = response.links
        this.form.link = response.redirect_url
        this.form.postId = response.postId
      } else {
        if (response.error.message) {
          this.$root.$bvToast.toast(
            response.error.data.message || response.error.message,
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          )
        } else {
          this.$root.$bvToast.toast("Internal Server Error", {
            title: `Error`,
            variant: "danger",
            solid: true
          })
        }
      }

      if (!this.similarLinks.length && !response.error) {
        this.resolve()
      }
    },

    resolve(forceResolve) {
      this.resolveState = "resolving"

      if (!this.form.link) {
        this.resolveState = "ready"
        this.resetForm()
        return
      }

      LineIncidentLogsService.resolve({
        cusId: this.selectedBrand.cusId,
        link: this.form.link,
        forceResolve: forceResolve,
        postId: this.form.postId
      }).then(response => {
        if (!response.error) {
          this.form = {
            ...this.form,
            link: response.url,
            source: response.source,
            username: response.username,
            text: response.text,
            date: response.date,
            count: {
              like: response.like,
              comment: response.comment,
              retweet: response.retweet,
              view: response.view
            },
            personalPost: response.isPersonal,
            fanCount: response.fanCount,
            isFacebookGroup: response.isFacebookGroup,
            pageName: response.pageName,
            postId: response.postId
            // intention: this.intentionOpts[0]
          }
          this.resolveState = "success"
        } else {
          if (response.error.isLinkInUse) {
            this.resolveState = "exist"
          } else {
            if (
              response.error &&
              response.error.data &&
              response.error.message
            ) {
              this.$root.$bvToast.toast(response.error.data.message, {
                title: `Error`,
                variant: "danger",
                solid: true
              })
            } else {
              this.$root.$bvToast.toast("Internal Server Error", {
                title: `Error`,
                variant: "danger",
                solid: true
              })
            }

            this.resolveState = "retry"
            let link = this.form.link
            this.resetForm()
            this.form.link = link
          }
        }
      })
    },
    resolveLinkFromCookie() {
      let link = Cookies.get("lineincident-link")
      if (link && link.startsWith("http") && this.form.link !== link) {
        this.form.link = link
        this.onChangeLink()
        Cookies.set("lineincident-link", "", {
          expires: 0,
          domain: ".ask-dom.com"
        })
      }
    },
    getRemarkText() {
      // if (typeof this.form.count.like !== "number") return ""

      const source = this.form.source
      let likeText = `Like: ${this.form.count.like.toLocaleString()}\n`
      let shareText = `Share: ${this.form.count.retweet.toLocaleString()}\n`
      let commentText = `Comment: ${this.form.count.comment.toLocaleString()}\n`
      let retweetText = `Retweet: ${this.form.count.retweet.toLocaleString()}\n`
      let viewText = `View: ${this.form.count.view.toLocaleString()}\n`
      let text =
        source === "website"
          ? likeText + shareText + commentText
          : source === "facebook"
          ? likeText + shareText + commentText
          : source === "twitter"
          ? likeText + retweetText
          : source === "youtube"
          ? likeText + commentText
          : source === "pantip"
          ? likeText + shareText + commentText
          : source === "instagram"
          ? likeText + commentText
          : likeText + commentText + shareText
      text = text + (this.form.count.view === 0 ? "" : viewText)
      text = text.replace(/\n$/, "")
      return text
    }
  }
}
</script>

<style></style>
